.print-section {
  background-color: #eaedf0;
}

.print-section .print-swiper {
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 1600px) {
  .print-section .print-swiper {
    padding-left: 48px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper {
    padding-left: 46px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper {
    padding-left: 44px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper {
    padding-left: 42px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper {
    padding-left: 40px;
  }
}

@media (max-width: 1600px) {
  .print-section .print-swiper {
    padding-right: 48px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper {
    padding-right: 46px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper {
    padding-right: 44px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper {
    padding-right: 42px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper {
    padding-right: 40px;
  }
}

.print-section .print-swiper .swiper-slide .item-img {
  display: block;
}

.print-section .print-swiper .swiper-slide .item-img b {
  background-repeat: no-repeat;
  background-size: contain;
}

.print-section .print-swiper .swiper-slide .item-img .img-mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all .4s;
}

.print-section .print-swiper .swiper-slide .item-img .img-mask span {
  background-color: #0ea292;
  color: #fff;
  font-weight: bold;
  border-radius: 50px;
  letter-spacing: 1px;
  font-size: 18px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-left: 27px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-left: 24px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-left: 21px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-left: 18px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-right: 27px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-right: 24px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-right: 21px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-right: 18px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-right: 15px;
  }
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-top: 7.4px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-top: 6.8px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-top: 6.2px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-top: 5.6px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-top: 5px;
  }
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-bottom: 7.4px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-bottom: 6.8px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-bottom: 6.2px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-bottom: 5.6px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-slide .item-img .img-mask span {
    padding-bottom: 5px;
  }
}

.print-section .print-swiper .swiper-slide .item-img:hover .img-mask {
  opacity: 1;
}

.print-section .print-swiper .swiper-slide .item-text {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  color: #000;
  padding: 5px;
  position: relative;
  transition: color 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-slide .item-text {
    font-size: 20.8px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-slide .item-text {
    font-size: 19.6px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-slide .item-text {
    font-size: 18.4px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-slide .item-text {
    font-size: 17.2px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-slide .item-text {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-slide .item-text {
    margin-top: 18px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-slide .item-text {
    margin-top: 16px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-slide .item-text {
    margin-top: 14px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-slide .item-text {
    margin-top: 12px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-slide .item-text {
    margin-top: 10px;
  }
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-slide .item-text {
    margin-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-slide .item-text {
    margin-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-slide .item-text {
    margin-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-slide .item-text {
    margin-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-slide .item-text {
    margin-bottom: 10px;
  }
}

.print-section .print-swiper .swiper-slide .item-text a {
  display: block;
}

.print-section .print-swiper .swiper-slide .item-text::before, .print-section .print-swiper .swiper-slide .item-text::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #0ea292;
  position: absolute;
  left: 0;
  transform-origin: left;
  transition: transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transform: scaleX(0);
  z-index: 1;
}

.print-section .print-swiper .swiper-slide .item-text::before {
  top: -1px;
}

.print-section .print-swiper .swiper-slide .item-text::after {
  bottom: -1px;
}

.print-section .print-swiper .swiper-slide .item-icon a {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.print-section .print-swiper .swiper-slide .item-icon .iconfont {
  border-radius: 50%;
  color: #0ea292;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0ea292;
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  flex-shrink: 0;
  font-size: 22px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    font-size: 21.2px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    font-size: 20.4px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    font-size: 19.6px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    font-size: 18.8px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    width: 39.2px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    width: 38.4px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    width: 37.6px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    width: 36.8px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    width: 36px;
  }
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    height: 39.2px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    height: 38.4px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    height: 37.6px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    height: 36.8px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    height: 36px;
  }
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    margin-right: 9px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    margin-right: 8px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    margin-right: 7px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    margin-right: 6px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-slide .item-icon .iconfont {
    margin-right: 5px;
  }
}

.print-section .print-swiper .swiper-slide .item-icon span {
  color: #000;
  margin-right: 10px;
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-slide .item-icon span {
    margin-right: 9px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-slide .item-icon span {
    margin-right: 8px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-slide .item-icon span {
    margin-right: 7px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-slide .item-icon span {
    margin-right: 6px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-slide .item-icon span {
    margin-right: 5px;
  }
}

.print-section .print-swiper .swiper-slide .item-icon em {
  color: #999999;
}

.print-section .print-swiper .swiper-slide .item-icon:hover .iconfont {
  background-color: #0ea292;
  color: #fff;
}

.print-section .print-swiper .swiper-slide:hover .item-text {
  color: #0ea292;
}

.print-section .print-swiper .swiper-slide:hover .item-text::before, .print-section .print-swiper .swiper-slide:hover .item-text::after {
  transform: scaleX(1);
}

.print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
  border-radius: 50%;
  background-color: #eef3ff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 37%;
  width: 60px;
  height: 60px;
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    top: 38%;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    top: 39%;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    top: 40%;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    top: 41%;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    top: 42%;
  }
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    width: 56px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    width: 52px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    width: 48px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    width: 44px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    width: 40px;
  }
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    height: 56px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    height: 52px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    height: 48px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    height: 44px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-button-prev, .print-section .print-swiper .swiper-button-next {
    height: 40px;
  }
}

.print-section .print-swiper .swiper-button-prev::after, .print-section .print-swiper .swiper-button-next::after {
  content: '';
}

.print-section .print-swiper .swiper-button-prev .iconfont, .print-section .print-swiper .swiper-button-next .iconfont {
  display: inline-block;
  color: #062745;
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  font-size: 20px;
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-button-prev .iconfont, .print-section .print-swiper .swiper-button-next .iconfont {
    font-size: 19.6px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-button-prev .iconfont, .print-section .print-swiper .swiper-button-next .iconfont {
    font-size: 19.2px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-button-prev .iconfont, .print-section .print-swiper .swiper-button-next .iconfont {
    font-size: 18.8px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-button-prev .iconfont, .print-section .print-swiper .swiper-button-next .iconfont {
    font-size: 18.4px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-button-prev .iconfont, .print-section .print-swiper .swiper-button-next .iconfont {
    font-size: 18px;
  }
}

.print-section .print-swiper .swiper-button-prev > div, .print-section .print-swiper .swiper-button-next > div {
  display: flex;
  overflow: hidden;
  width: 20px;
}

.print-section .print-swiper .swiper-button-prev {
  left: -50px;
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-button-prev {
    left: -41px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-button-prev {
    left: -32px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-button-prev {
    left: -23px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-button-prev {
    left: -14px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-button-prev {
    left: -5px;
  }
}

.print-section .print-swiper .swiper-button-prev:hover .iconfont {
  color: #0ea292;
  transform: translateX(-100%);
}

.print-section .print-swiper .swiper-button-prev > div {
  justify-content: flex-start;
}

.print-section .print-swiper .swiper-button-next {
  right: -50px;
}

@media (max-width: 1600px) {
  .print-section .print-swiper .swiper-button-next {
    right: -41px;
  }
}

@media (max-width: 1366px) {
  .print-section .print-swiper .swiper-button-next {
    right: -32px;
  }
}

@media (max-width: 1024px) {
  .print-section .print-swiper .swiper-button-next {
    right: -23px;
  }
}

@media (max-width: 991px) {
  .print-section .print-swiper .swiper-button-next {
    right: -14px;
  }
}

@media (max-width: 767px) {
  .print-section .print-swiper .swiper-button-next {
    right: -5px;
  }
}

.print-section .print-swiper .swiper-button-next:hover .iconfont {
  color: #0ea292;
  transform: translateX(100%);
}

.print-section .print-swiper .swiper-button-next > div {
  justify-content: flex-end;
}

@import "../../assets/styles/utils";

.print-section{
  background-color: $color-bg;
  .print-swiper{
    position: relative;
    @include res(padding-left, 50px, 40 / 50);
    @include res(padding-right, 50px, 40 / 50);
    .swiper-slide{
      .item-img{
        display: block;
        b{
          background-repeat: no-repeat;
          background-size: contain;
        }
        .img-mask{
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,.2);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: all .4s;
          span{
            background-color: $color-green;
            color: #fff;
            font-weight: bold;
            border-radius: 50px;
            letter-spacing: 1px;
            @include res(font-size,18px, 16 / 18);
            @include res(padding-left,30px, 15 / 30);
            @include res(padding-right,30px, 15 / 30);
            @include res(padding-top,8px, 5 / 8);
            @include res(padding-bottom,8px, 5 / 8);
          }
        }
        &:hover{
          .img-mask{
            opacity: 1;
          }
        }
      }
      .item-text{
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        color: #000;
        padding: 5px;
        position: relative;
        transition: color .7s $anime-bezier;
        @include res(font-size,22px, 16 / 22);
        @include res(margin-top,20px, 10 / 20);
        @include res(margin-bottom,15px, 10 / 15);
        a{
          display: block;
        }
        &::before,&::after{
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background-color: $color-green;
          position: absolute;
          left: 0;
          transform-origin: left;
          transition: transform .7s $anime-bezier;
          transform: scaleX(0);
          z-index: 1;
        }
        &::before{
          top: -1px;
        }
        &::after{
          bottom: -1px;
        }
      }
      .item-icon{
        a{
          display: flex;
          align-items: center;
          white-space: nowrap;
        }
        .iconfont{
          border-radius: 50%;
          color: $color-green;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $color-green;
          transition: all .7s $anime-bezier;
          flex-shrink: 0;
          @include res(font-size, 22px, 18 / 22);
          @include res(width, 40px, 36 / 40);
          @include res(height, 40px, 36 / 40);
          @include res(margin-right, 10px, 5 / 10);
        }
        span{
          color: #000;
          @include res(margin-right, 10px, 5 / 10);
        }
        em{
          color: $color-desc;
        }
        &:hover{
          .iconfont{
            background-color: $color-green;
            color: #fff;
          }
        }
      }
      &:hover{
        .item-text{
          color: $color-green;
          &::before,&::after{
            transform: scaleX(1);
          }
        }
        // .item-icon{
        //   .iconfont{
        //     background-color: $color-green;
        //     color: #fff;
        //   }
        // }
      }
    }
    .swiper-button-prev,.swiper-button-next{
      border-radius: 50%;
      background-color: #eef3ff;
      display: flex;
      align-items: center;
      justify-content: center;
      @include res(top,37%, 42 / 37);
      @include res(width,60px, 40 / 60);
      @include res(height,60px, 40 / 60);
      &::after{
        content: '';
      }
      .iconfont{
        display: inline-block;
        color: $color-blue-dark;
        transition: all .7s $anime-bezier;
        @include res(font-size, 20px, 18 / 20);
      }
      >div{
        display: flex;
        overflow: hidden;
        @include res(width, 20px);
      }
    }
    .swiper-button-prev{
      @include res(left, -50px, -5 / -50);
      &:hover{
        .iconfont{
          color: $color-green;
          transform: translateX(-100%);
        }
      }
      >div{
        justify-content: flex-start;
      }
    }
    .swiper-button-next{
      @include res(right, -50px, -5 / -50);
      &:hover{
        .iconfont{
          color: $color-green;
          transform: translateX(100%);
        }
      }
      >div{
        justify-content: flex-end;
      }
    }
  }
}
@import "../../assets/styles/utils";

.field-banner{
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include res(height, 480px, 400 / 480);
  &.active{
    .container .cont h1::after{
      transform: scaleX(1);
    }
  }
  .bg-img{
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .container{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 100%;
    position: relative;
    z-index: 2;
    .cont{
      width: 100%;
      @include res(margin-bottom, 60px);
      h1{
        color: #fff;
        display: flex;
        align-items: center;
        line-height: 1.3;
        text-align: center;
        @include res(font-size, 50px, 36 / 50);
        &::after{
          content: '';
          display: block;
          flex-grow: 1;
          height: 1px;
          background-color: rgba(255,255,255,.3);
          transition: transform 1s $anime-bezier;
          transform: scaleX(0);
          transform-origin: left;
          @include res(margin-left, 20px, 10 / 20);
        }
      }
    }
  }
}
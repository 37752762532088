@import "../../assets/styles/utils";

.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  @include res(height, $header-height-base, $header-height-ratio);
  .logo {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    @include res(left, 65px, 10 / 65);
    img {
      display: block;
      width: auto;
      @include res(height, 34px, 16 / 34);
      // 添加30周年logo新增样式 - start
      @media screen and(max-width: 1700px) {
        @include res(height, 30px,(lg:25px,mmd:24px,xs:12px));
      }
      // 添加30周年logo新增样式 - end
    }
    // 添加30周年logo新增样式 - start
    .logo-30{
      position: absolute;
      top: 57%;
      left: 100%;
      transform: translateY(-50%);
      @include res(margin-left,35px, 10 / 35);
      @include res(height, 70px, 16 / 70);
      @media screen and(max-width: 1700px) {
        @include res(top, 54%,(lg:55%,mmd:56%));
        @include res(height, 50px,(mmd:45px,xs:25px));
        @include res(margin-left,20px,(mmd:10px,xs:5px));
      }
    }
    // 添加30周年logo新增样式 - end
  }
  .center-menu {
    height: 100%;
    display: flex;
    @include res(
      display,
      flex,
      (
        md: none,
      )
    );
    .menu-show-bg {
      display: none;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(6, 39, 69, 0.9);
      z-index: -1;
    }
    .menu-item {
      height: 100%;
      .menu-item-title {
        height: 100%;
        letter-spacing: 2px;
        @include res(font-size, 20px, 16 / 20);
        a {
          height: 100%;
          display: flex;
          align-items: center;
          color: rgba(255, 255, 255, 0.5);
          transition: color 0.4s $anime-bezier;
          @include res(padding-left, 15px, 8 / 15);
          @include res(padding-right, 15px, 8 / 15);
          span {
            position: relative;
            &::after {
              content: "";
              display: block;
              width: 100%;
              height: 2px;
              background-color: #fff;
              position: absolute;
              left: 0;
              bottom: 0px;
              transform-origin: right;
              transform: scaleX(0);
              transition: transform 0.4s $anime-bezier;
            }
          }
        }
      }
      .menu-item-sub {
        position: absolute;
        top: 100%;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 0.7s $anime-bezier, visibility 0.5s $anime-bezier;
        @include res(left, 65px, 10 / 65);
        @include res(right, 65px, 10 / 65);

        &.zyly {
          display: flex;
          align-items: stretch;
          &::before,
          &::after {
            content: "";
            display: block;
            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 0;
            transform: scaleY(0.9);
            transform-origin: top;
            transition: transform 0.7s $anime-bezier;
          }
          &::before {
            left: 0;
            background-color: $color-bg;
          }
          &::after {
            right: 0;
            background-color: #fff;
          }
          .zyly-item {
            width: 50%;
            box-sizing: border-box;
            flex-shrink: 0;
            position: relative;
            z-index: 1;
            @include res(padding-top, 95px, 50 / 95);
            @include res(padding-bottom, 120px, 50 / 120);
            @include res(padding-left, 65px, 20 / 65);
            @include res(padding-right, 65px, 20 / 65);
            .zyly-item-title {
              color: $color-green;
              font-weight: bold;
              overflow: hidden;
              @include res(font-size, 18px, 14 / 18);
              @include res(margin-bottom, 45px, 20 / 45);
            }
            .zyly-item-link {
              display: flex;
              flex-wrap: wrap;
              overflow-y: auto;
              align-items: flex-start;
              align-content: flex-start;
              justify-content: flex-start;
              @include res(font-size, 18px, 14 / 18);
              @include res(max-height, 360px);
              /*滚动条样式*/
              &::-webkit-scrollbar {
                width: 4px;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: rgba(189, 189, 189, 0.4);
              }
              &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
                border-radius: 0;
                background: rgba(0, 0, 0, 0);
              }
              a {
                line-height: 1.4;
                color: #000;
                overflow: hidden;
                @include res(width, 33.33%);
                @include res(margin-top, 12px, 5 / 12);
                .ani-y {
                  transition: transform 0.8s $anime-bezier;
                }
                .ani-line::after {
                  bottom: 0;
                  background-color: $color-green;
                }
              }
            }
          }
        }
        &.flgc {
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 0;
            background-color: #fff;
            transform: scaleY(0.9);
            transform-origin: top;
            transition: transform 0.7s $anime-bezier;
          }
          .cont {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            @include res(padding-left, 95px, 20 / 95);
            @include res(padding-right, 95px, 20 / 95);
            @include res(padding-top, 90px, 50 / 90);
            @include res(padding-bottom, 80px, 40 / 80);
            .left {
              flex-shrink: 0;
              @include res(width, 59.2%);
              .ani-line-two::after {
                background-color: #fff;
              }
              .more-theme {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                span {
                  color: #000;
                  font-weight: bold;
                  letter-spacing: 5px;
                  overflow: hidden;
                  @include res(font-size, 18px, 14 / 18);
                  @include res(margin-right, 20px, 10 / 20);
                  @include res(margin-bottom, 7px, 5 / 7);
                }
                a {
                  overflow: hidden;
                  @include res(margin-right, 7px, 5 / 7);
                  @include res(margin-bottom, 7px, 5 / 7);
                  &:hover {
                    em {
                      background-color: $color-green;
                      color: #fff;
                      .iconfont {
                        color: #fff;
                      }
                    }
                  }
                  &.more {
                    em {
                      padding: 0;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: #000;
                      @include res(width, 35px);
                      @include res(height, 35px);
                      .iconfont {
                        font-size: 20px;
                        margin-top: 3px;
                        transition: all 0.7s $anime-bezier;
                      }
                    }
                  }
                  em {
                    border-radius: 50px;
                    border: 1px solid $color-green;
                    color: $color-green;
                    transition: all 0.7s $anime-bezier;
                    @include res(padding-left, 25px, 15 / 25);
                    @include res(padding-right, 25px, 15 / 25);
                    @include res(padding-top, 3px);
                    @include res(padding-bottom, 3px);
                  }
                }
              }
            }
            .right {
              flex-shrink: 0;
              @include res(width, 36.13%);
              .right-title {
                font-weight: bold;
                color: #000;
                overflow: hidden;
                @include res(margin-bottom, 25px, 15 / 25);
              }
              .right-headline {
                color: #000;
                overflow: hidden;
                transition: color 0.7s $anime-bezier;
                @include res(font-size, 22px, 16 / 22);
                a {
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                }
                &:hover {
                  color: $color-green;
                }
                span {
                  display: inline-block;
                  float: left;
                  border-radius: 50px;
                  background-color: #e9f4f3;
                  color: $color-green;
                  @include res(font-size, 16px, 14 / 16);
                  @include res(padding-left, 20px, 10 / 20);
                  @include res(padding-right, 20px, 10 / 20);
                  @include res(padding-top, 3px, 2 / 3);
                  @include res(margin-right, 12px, 6 / 12);
                  @include res(margin-top, 5px, 3 / 5);
                }
              }
              .common-latest-news-list {
                border-top: 1px solid #b2b2b2;
                @include res(padding-top, 30px, 15 / 30);
                @include res(margin-top, 20px, 10 / 20);
                ul li {
                  overflow: hidden;
                }
              }
              .more {
                display: flex;
                justify-content: flex-end;
                overflow: hidden;
                @include res(margin-top, 50px, 20 / 50);
              }
            }
          }
        }
      }
      &.on {
        .menu-item-title a {
          color: #fff;
        }
      }
      &:hover{
        .menu-item-title {
          a {
            color: #fff;
            span::after {
              transform-origin: left;
              transform: scaleX(1);
            }
          }
        }
      }
      &.show {
        .menu-item-title {
          a {
            color: #fff;
            span::after {
              transform-origin: left;
              transform: scaleX(1);
            }
          }
        }
        .menu-item-sub {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
          &.zyly {
            &::before,
            &::after {
              transform: scaleY(1);
            }
            // .ani-y{
            //   transform: translateY(0);
            // }
          }
          &.flgc {
            &::after {
              transform: scaleY(1);
            }
            // .ani-y{
            //   transform: translateY(0);
            // }
          }
        }
      }
    }
  }
}

.right-nav-icon {
  position: fixed;
  z-index: 500;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include res(right, 65px, 10 / 65);
  @include res(height, $header-height-base, $header-height-ratio);
  &.scroll {
    .language {
      opacity: 0;
      visibility: hidden;
    }
    .menu-icon-cont {
      // width: auto;
      background-color: #f7f7f8;
      @include res(width,185px,(lg:173px,mmd:161px,md:148px,sm:136px,xs:124px));
      .search-form {
        @include res(width, 50px, 30 / 50);
        .open-search {
          opacity: 1;
        }
      }
      .menu-icon {
        background-color: $color-blue-dark;
      }
    }
  }
  &.scroll-open-search {
    .menu-icon-cont {
      // width: auto;
      @include res(width,475px,(lg:427px,mmd:369px,md:360px,sm:352px,xs:344px));
      .search-form {
        @include res(
          width,
          340px,
          (
            lg: 300px,
            mmd: 250px,
            md: 250px,
          )
        );
        @media screen and (max-width: 320px) {
          width: 200px;
        }
        button {
          opacity: 1;
        }
        .open-search {
          display: none;
        }
      }
    }
  }
  &.menu-open {
    .menu-icon-cont {
      // width: auto;
      background-color: #fff;
      @include res(width,535px,(lg:427px,mmd:369px,md:360px,sm:352px,xs:344px));
      .search-form {
        @include res(
          width,
          400px,
          (
            lg: 300px,
            mmd: 250px,
            md: 250px,
          )
        );
        @media screen and (max-width: 320px) {
          width: 200px;
        }
        button {
          opacity: 1;
        }
        .open-search {
          display: none;
        }
      }
      .menu-icon {
        background-color: $color-blue-dark;
      }
    }
  }
  .language {
    color: #fff;
    font-family: "itc-dm";
    position: absolute;
    transition: all 0.7s $anime-bezier;
    white-space: nowrap;
    @include res(right, 154px, 95 / 154);
    @include res(font-size,16px, 14 / 16);
    &:hover{
      .sub-language{
        transform: scaleY(1);
        opacity: 1;
        visibility: visible;
      }
    }
    .title {
      display: flex;
      align-items: center;
      .iconfont {
        @include res(margin-left, 10px, 0 / 10);
      }
    }
    .sub-language{
      position: absolute;
      top: 100%;
      left: 0;
      transition: all .3s;
      transform-origin: top;
      transform: scaleY(.9);
      opacity: 0;
      visibility: hidden;
      a{
        display: inline-block;
        position: relative;
        &::after{
          content: '';
          display: block;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #fff;
          transform-origin: right;
          transform: scaleX(0);
          transition: transform 0.4s $anime-bezier;
          @include res(height, 2px, 1 / 2);
        }
        &:hover{
          &::after{
            transform-origin: left;
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .menu-icon-cont {
    border-radius: 50px;
    border: 1px solid #fff;
    display: flex;
    align-items: stretch;
    // background-color: #fff;
    justify-content: flex-end;
    transition: background-color 0.7s $anime-bezier,width 0.7s $anime-bezier;
    @include res(width,133px,(lg:127px,mmd:119px,md:110px,sm:102px,xs:92px));
    .search-form {
      position: relative;
      display: flex;
      align-items: stretch;
      justify-content: flex-end;
      overflow: hidden;
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      transition: width 0.7s $anime-bezier;
      @include res(width, 0px);
      input {
        border: none;
        flex-grow: 1;
        background-color: transparent;
        @include res(font-size, 18px, 14 / 18);
        @include res(padding-left, 30px, 15 / 30);
      }
      button {
        flex-shrink: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        opacity: 0;
        @include res(width, 50px, 30 / 50);
        .iconfont {
          color: #000;
          @include res(font-size, 21px, 20 / 21);
        }
      }
      .open-search {
        position: absolute;
        right: 0;
        height: 100%;
        z-index: 2;
        // background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0;
        @include res(width, 50px, 30 / 50);
        .iconfont {
          color: #000;
          @include res(font-size, 21px, 20 / 21);
        }
      }
    }
    .menu-icon {
      display: flex;
      align-items: center;
      border-radius: 50px;
      // background-color: $color-blue-dark;
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
      transition: background-color 0.7s $anime-bezier;
      @include res(padding-left, 25px, 10 / 25);
      @include res(padding-right, 25px, 10 / 25);
      @include res(height, 50px, 35 / 50);
      @include res(font-size,16px, 14 / 16);
      
      &.active {
        .hamburger-t {
          span {
            transform: translateY(-100%);
          }
        }
      }
      .hamburger {
        > div::after {
          width: 50%;
          right: 0;
        }
        &.active {
          width: 16px;
          margin-left: 7px;
          > div {
            height: 3px;
            width: 16px;
            &::before {
              height: 3px;
            }
            &::after {
              height: 3px;
              width: 100%;
            }
          }
        }
      }
      .hamburger-t {
        font-family: "itc-dm";
        display: flex;
        flex-direction: column;
        overflow: hidden;
        line-height: 1.2;
        @include res(height, 18px);
        @include res(margin-left, 10px, 5 / 10);
        span {
          display: inline-block;
          transition: transform 0.45s $anime-bezier;
          @include res(
            padding-top,
            0,
            (
              xs: 2px,
            )
          );
        }
      }
    }
  }
}
.menu {
  transition: all 0.7s $anime-bezier;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 499;
  @include res(opacity, 0);
  @include res(visibility, hidden);
  &.show {
    opacity: 1;
    visibility: visible;
    .main-menu {
      &::after {
        transform: scaleY(1);
      }
    }
    .sub-menu::after {
      transform: scaleY(1);
    }
  }
  .main-menu {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    height: 100vh;
    @include res(padding-top, $header-height-base, $header-height-ratio);
    @include res(padding-left, 65px, 10 / 65);
    @include res(padding-right, 65px, 10 / 65);
    @include res(padding-bottom, 65px, 10 / 65);
    @include res(
      width,
      666px,
      (
        lg: 530px,
        mmd: 460px,
        md: 430px,
        sm: 100%,
      )
    );
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: $color-blue-dark;
      transform-origin: top;
      transform: scaleY(0.9);
      transition: transform 0.7s $anime-bezier;
    }
    ul {
      height: 100%;
      overflow-y: auto;
      li {
        text-align: right;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        @include res(font-size, 18px, 16 / 18);
        @include res(padding-left, 15px, 20 / 15);
        @include res(padding-right, 15px, 20 / 15);
        &:first-child {
          border-top: 1px solid rgba(255, 255, 255, 0.2);
        }
        &:hover,
        &.on {
          color: $color-green;
        }
        div {
          overflow: hidden;
        }
        a {
          display: block;
          width: 100%;
          transition: all 0.7s $anime-bezier;
          @include res(padding-top, 20px, 15 / 20);
          @include res(padding-bottom, 20px, 15 / 20);
        }
        span {
          display: block;
          transition: all 0.7s $anime-bezier;
          @include res(padding-top, 20px, 15 / 20);
          @include res(padding-bottom, 20px, 15 / 20);
        }
        .iconfont {
          font-weight: normal;
          transform: rotate(90deg);
          transition: all 0.7s $anime-bezier;
          @include res(font-size, 28px, 22 / 28);
        }
      }
    }
  }
  .sub-menu {
    position: absolute;
    top: 0;
    height: 100vh;
    transition: transform 0.7s $anime-bezier;
    @include res(
      left,
      0,
      (
        sm: auto,
      )
    );
    @include res(
      right,
      auto,
      (
        sm: 100%,
      )
    );
    @include res(
      width,
      calc(100% - 666px),
      (
        lg: calc(100% - 530px),
        mmd: calc(100% - 460px),
        md: calc(100% - 430px),
        sm: (
          100%,
        ),
      )
    );
    &.mob-show {
      transform: translateX(100%);
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: #fff;
      transform-origin: top;
      transform: scaleY(0.9);
      transition: transform 0.7s $anime-bezier;
    }
    .mob-back {
      background-color: #eaedf0;
      align-items: center;
      justify-content: flex-start;
      box-sizing: content-box;
      @include res(
        padding-top,
        0,
        (
          xs: 60px,
        )
      );
      @include res(
        display,
        none,
        (
          sm: flex,
        )
      );
      @include res(padding-left, 150px, 20 / 150);
      @include res(
        height,
        78px,
        (
          xs: 40px,
        )
      );
      .iconfont {
        margin-right: 10px;
        transform: rotate(90deg);
        @include res(font-size, 30px, 20 / 30);
      }
    }
    .zyly,
    .flgc {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all 0.7s $anime-bezier;
      @include res(
        height,
        100%,
        (
          sm: calc(100% - 78px),
          xs: calc(100% - 100px),
        )
      );
      &.subnav-show {
        opacity: 1;
        visibility: visible;
        .ani-y {
          transform: translateY(0);
        }
      }
    }
    .zyly {
      .zyly-tab {
        background-color: #eaedf0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: bold;
        color: $color-blue-dark;
        box-sizing: border-box;
        letter-spacing: 4px;
        @include res(font-size, 20px, 16 / 20);
        @include res(padding-top, 50px, 10 / 50);
        @include res(padding-left, 150px, 20 / 150);
        @include res(padding-right, 150px, 20 / 150);
        @include res(height, $header-height-base, $header-height-ratio);
        > div {
          overflow: hidden;
          @include res(margin-right, 60px, 20 / 60);
          &.on {
            .ani-line::after {
              transform-origin: left;
              transform: scaleX(1);
            }
          }
        }
      }
      .zyly-sub-nav {
        box-sizing: border-box;
        @include res(
          height,
          calc(100% - 150px),
          (
            lg: calc(100% - 132px),
            mmd: calc(100% - 114px),
            md: calc(100% - 96px),
            sm: calc(100% - 78px),
            xs: calc(100% - 60px),
          )
        );
        @include res(padding-top, 80px, 20 / 80);
        @include res(padding-bottom, 50px, 20 / 50);
        @include res(padding-left, 150px, 20 / 150);
        @include res(padding-right, 150px, 20 / 150);
        .swiper {
          height: 100%;
          .item {
            height: 100%;
            overflow-y: auto;
            /*滚动条样式*/
            &::-webkit-scrollbar {
              width: 4px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              background: rgba(189, 189, 189, 0.4);
            }
            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
              border-radius: 0;
              background: rgba(0, 0, 0, 0);
            }
            &::after {
              content: "";
              display: block;
              clear: both;
            }
            a {
              display: block;
              color: #000;
              float: left;
              overflow: hidden;
              @include res(font-size, 18px, 16 / 18);
              @include res(
                width,
                33.33%,
                (
                  md: 50%,
                )
              );
              @include res(margin-bottom, 35px, 15 / 35);
            }
          }
        }
      }
    }
    .flgc {
      .flgc-tab {
        background-color: #eaedf0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        color: $color-blue-dark;
        box-sizing: border-box;
        @include res(padding-top, 50px, 10 / 50);
        @include res(padding-left, 150px, 20 / 150);
        @include res(padding-right, 150px, 20 / 150);
        @include res(height, $header-height-base, $header-height-ratio);
        .title {
          color: $color-blue-dark;
          letter-spacing: 4px;
          @include res(font-size, 20px, 16 / 20);
        }
      }
      .flgc-cont {
        box-sizing: border-box;
        overflow-y: auto;
        @include res(
          height,
          calc(100% - 150px),
          (
            lg: calc(100% - 132px),
            mmd: calc(100% - 114px),
            md: calc(100% - 96px),
            sm: calc(100% - 78px),
            xs: calc(100% - 60px),
          )
        );
        @include res(padding-top, 50px, 20 / 50);
        @include res(padding-bottom, 50px, 20 / 50);
        @include res(padding-left, 150px, 20 / 150);
        @include res(padding-right, 150px, 20 / 150);
        /*滚动条样式*/
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: rgba(189, 189, 189, 0.4);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
          border-radius: 0;
          background: rgba(0, 0, 0, 0);
        }
        .flgc-latest-news {
          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include res(margin-bottom, 25px, 15 / 25);
            .t{
              font-weight: bold;
              color: #000;
              overflow: hidden;
            }
          }
          .headline {
            color: #000;
            overflow: hidden;
            transition: color 0.7s $anime-bezier;
            @include res(font-size, 22px, 16 / 22);
            a {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
            &:hover {
              color: $color-green;
            }
            span {
              display: inline-block;
              float: left;
              border-radius: 50px;
              background-color: #e9f4f3;
              color: $color-green;
              @include res(font-size, 16px, 14 / 16);
              @include res(padding-left, 20px, 10 / 20);
              @include res(padding-right, 20px, 10 / 20);
              @include res(padding-top, 3px, 2 / 3);
              @include res(margin-right, 12px, 6 / 12);
              @include res(margin-top, 5px, 0 / 5);
            }
          }
          .common-latest-news-list {
            border-top: 1px solid #b2b2b2;
            @include res(padding-top, 30px, 15 / 30);
            @include res(margin-top, 20px, 10 / 20);
            ul li a{
              @include res(height,29px, 32 / 29);
            }
          }
        }
      }
    }
  }
}
